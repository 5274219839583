// transition: all 300ms ease;

import React, { useEffect, useState, useRef } from "react";

import { navigate } from "gatsby";
import Helmet from "react-helmet";
import { SpinnerContainer } from "components/Admin/styles";
import Spinner from "react-spinkit";
import { auth } from "api/auth";
import styled from "styled-components";
import Markdown from "react-remarkable-with-plugins";
import Label from "components/Elements/Label";

import AuthUserContext from "components/Session/AuthUserContext";
import withAuthorization from "components/Session/withAuthorization";
import {
  fetchWebinarData,
  fetchUserDataByUid,
  checkUserDataForCohort,
  fetchActiveCohort,
  toggleOnlineUsers,
  getWebinarOnlineUsers
} from "api/db";

import {
  WebinarHeader,
  WebinarYouTubeContainer,
  SubmitBox,
  SubmitButton,
  SubmitInput,
  Live,
  Premiered
} from "components/Cohort/styles";
import { webinarPageClose, webinarPageHit, webinarSubmission } from "../../api/db";

function Webinar({ location }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isCohortUser, setIsCohortUser] = useState(false);
  const [cohortNum, setCohortNum] = useState(null); // cohort will be just a number
  const [enlightUser, setEnlightUser] = useState(null);
  const [webinarSlug, setWebinarSlug] = useState(null);
  const [webinarData, setWebinarData] = useState(null);
  const [link, setLink] = useState("");
  const [timeString, setTimeString] = useState("");
  const [liveCounter, setLiveCounter] = useState([]);
  let pushKey = useRef("");


  

  useEffect(() => {
    let cohortNum = location.pathname.slice(8).match(/\d+/g)[0]; // only the number, accounts for double digits
    setCohortNum(cohortNum);
    let webinar = location.pathname.slice(16); // going to be the webinar slug
    webinar = webinar.slice(webinar.indexOf("/") + 1); // just in case on double digit cohort
    setWebinarSlug(webinar);

    getWebinarOnlineUsers(cohortNum, webinar).on("child_added", snapshot => {
      if (snapshot.val() == true) {
        let array = liveCounter;
        array.push(snapshot.val());
        setLiveCounter([...array]);
      }
    });

    function cleanUp(e) {
      e.preventDefault();
      let date = new Date();
      webinarPageClose(auth.currentUser.uid, webinar, cohortNum, date.getTime(), pushKey);
      toggleOnlineUsers(auth.currentUser.uid, cohortNum, webinar, "close");
      return e.returnValue = "Hello";
    }

    if (typeof window !== "undefined") {
      window.addEventListener("beforeunload", cleanUp);
    }

    fetchUserDataByUid(auth.currentUser.uid).once("value", snapshot => {
      let userData = snapshot.val();
      let cohortTag = "";
      if (userData) {
        cohortTag = checkUserDataForCohort(userData);
        fetchActiveCohort().once("value", snapshot => {
          let activeCohort = snapshot.val();
          if (
            userData.tags &&
            userData.tags != undefined &&
            (userData.tags.includes("team") || userData.tags.includes("mentor"))
          ) {
            cohortTag = "cohort " + activeCohort;
          }
          if (cohortTag != "" && cohortTag == "cohort " + cohortNum) {
            setIsCohortUser(true);
            setEnlightUser(userData.id);
            fetchWebinarData(webinar, cohortNum).once("value", snapshot => {
              let webinarData = snapshot.val();
              if (webinarData) {
                setWebinarData(webinarData);
                if (webinarData.timestamp - Date.now() > 0) {
                  startTimer(webinarData.timestamp);
                }
                let date = new Date();
                webinarPageHit(auth.currentUser.uid, webinar, cohortNum, date.getTime()).then(snap => {
                  pushKey = snap.key;
                })
                toggleOnlineUsers(
                  auth.currentUser.uid,
                  cohortNum,
                  webinar,
                  "arrival"
                );
                setIsLoading(false);
              } else {
                navigate("/404");
              }
            });
          } else {
            navigate("/404");
          }
        });
      } else {
        navigate("/404");
      }
    });

    function startTimer(timestamp) {
      setInterval(() => {
        let milliseconds = timestamp - Date.now();
        var day, hour, minute, seconds;
        seconds = Math.floor(milliseconds / 1000);
        minute = Math.floor(seconds / 60);
        seconds = seconds % 60;
        hour = Math.floor(minute / 60);
        minute = minute % 60;
        day = Math.floor(hour / 24);
        hour = hour % 24;
        if (day > 0) {
          setTimeString(
            `${day} days, ${hour} hours, ${minute} minutes, ${seconds} seconds`
          );
        } else {
          setTimeString(`${hour} hours, ${minute} minutes, ${seconds} seconds`);
        }
      }, 1000);
    }

    return () => {
      window.removeEventListener("beforeunload", cleanUp);
    }

  }, [location]);

  return (
    <AuthUserContext.Consumer>
      {authUser =>
        isCohortUser ? (
          <>
            <Helmet title={webinarData && webinarData.title} />

            <WebinarHeader>
              <Label>
                {" "}
                <a href="/cohort/dashboard"> &larr; Back</a>{" "}
              </Label>
              <br />
              <h1>{webinarData && webinarData.title}</h1>
              <h2>{webinarData && webinarData.subtitle}</h2>

              {webinarData && webinarData.timestamp - Date.now() < 0 ? (
                <>
                  <div>
                    {webinarData.type == "youtube" ? (
                      <WebinarYouTubeContainer>
                        <article>
                          <iframe
                            id="video"
                            src={`${webinarData &&
                              webinarData.url}?loop=1&autoplay=1`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; modestbranding; loop;"
                            allowFullScreen
                            sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
                          ></iframe>
                        </article>
                      </WebinarYouTubeContainer>
                    ) : (
                      <>
                        {webinarData &&
                        Date.now() < webinarData.timestampEnd &&
                        webinarData.timestamp - Date.now() < 0 ? (
                          <>
                            <Live>&#9679; Live</Live>{" "}
                            {liveCounter.length > 0 ? (
                              <span>
                                {" "}
                                | <b>{liveCounter.length}</b> watching now{" "}
                              </span>
                            ) : null}
                          </>
                        ) : (
                          <Premiered>
                            Premiered on{" "}
                            {webinarData &&
                              new Date(
                                webinarData.timestamp
                              ).toDateString()}{" "}
                          </Premiered>
                        )}
                        <WebinarYouTubeContainer>
                          <article>
                            <iframe
                              id="video"
                              src={`${webinarData &&
                                webinarData.url}?portrait=0&byline=0&autoplay=1&loop=0&${
                                webinarData &&
                                Date.now() < webinarData.timestampEnd &&
                                webinarData.timestamp - Date.now() < 0
                                  ? `controls=1`
                                  : `controls=1`
                              }`}
                              frameborder="0"
                              allow="autoplay; fullscreen"
                              allowfullscreen
                            ></iframe>
                          </article>
                        </WebinarYouTubeContainer>
                      </>
                    )}
                  </div>
                  <article>
                    <Markdown>{webinarData && webinarData.resources} </Markdown>
                  </article>
                  <h4>Finish watching the webinar?</h4>
                  <h6>Submit your repl.it or Google Colab link.</h6>
                  <SubmitBox>
                    <SubmitInput
                      onChange={e => setLink(e.target.value)}
                      value={link}
                      placeholder="https://"
                    ></SubmitInput>
                    <br />
                    <SubmitButton
                      onClick={() => {
                        webinarSubmission(
                          auth.currentUser.uid,
                          webinarSlug,
                          cohortNum,
                          link
                        );
                        setLink("submitted!");
                      }}
                    >
                      Submit
                    </SubmitButton>
                  </SubmitBox>
                </>
              ) : (
                <h5>This webinar premieres in {timeString}. </h5>
              )}
            </WebinarHeader>
          </>
        ) : isLoading ? (
          <SpinnerContainer>
            <Spinner name="ball-scale-multiple" color="#438cee" />
          </SpinnerContainer>
        ) : (
          "404"
        )
      }
    </AuthUserContext.Consumer>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(Webinar);
